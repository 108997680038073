define("fx/templates/budgets/budget/budget-periods/budget-period/data/costs-ratios/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JaJSIf7t",
    "block": "[[[10,\"h1\"],[14,1,\"costs-ratios-title\"],[12],[1,[28,[35,0],[\"costs-ratios.title\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],[[24,\"aria-labelledby\",\"costs-ratios-title\"]],[[\"@table\",\"@budget\",\"@type\",\"@stepName\",\"@state\",\"@displayColumnTotals\",\"@readonly\"],[[30,0,[\"table\"]],[30,0,[\"budget\"]],\"COSTS\",[30,0,[\"stepName\"]],[30,0,[\"state\"]],true,[30,0,[\"isReadonly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"notes\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"costs-ratios.notes\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"costs-ratios.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"t\",\"ratios-table\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/costs-ratios/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});