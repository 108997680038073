define("fx/templates/budgets/budget/budget-periods/budget-period/data/intra-period/rates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJzyGRaD",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"data.intra-period.rates.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[12],[1,[28,[35,0],[\"coming-soon\"],null]],[13]],[],false,[\"t\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/intra-period/rates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});