define("fx/templates/budgets/budget/budget-periods/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URMM0RwU",
    "block": "[[[10,\"main\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"budget-periods.title\"],null]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"heading__actions\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,0,\"button\"]],[[\"@route\",\"@disabled\"],[\"budgets.budget.rolling-window-hedging\",[30,0,[\"rollingWindowHedingDisabled\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[\"rolling-window-hedging.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],[[24,0,\"button\"]],[[\"@route\",\"@disabled\"],[\"budgets.budget.batch-forward-transactions\",[30,0,[\"batchForwardTransactionsDisabled\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[\"batch-forward-transactions.title\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"budget-period-cards\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"budgetPeriods\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@budgetPeriod\",\"@displayDuplicate\",\"@openBudgetPeriodDuplicationModal\"],[[30,1],[28,[37,6],[[30,1],[30,0,[\"budgetPeriods\"]]],null],[30,0,[\"openBudgetPeriodDuplicationModal\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isShowingBudgetPeriodDuplicationModal\"]],[[[1,\"    \"],[8,[39,8],null,[[\"@onClose\",\"@budgetPeriodForDuplication\"],[[30,0,[\"closeBudgetPeriodDuplicationModal\"]],[30,0,[\"budgetPeriodForDuplication\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"budgetPeriod\"],false,[\"content\",\"t\",\"link-to\",\"each\",\"-track-array\",\"budget-period-card\",\"is-last\",\"if\",\"budget-period/duplicate/modal-dialog\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});