define("fx/templates/budgets/budget/budget-periods/budget-period/data/rates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n549u7QM",
    "block": "[[[10,\"h1\"],[14,1,\"rates-title\"],[12],[1,[28,[35,0],[\"rates.title\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"rates.instructions\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],[[24,\"aria-labelledby\",\"rates-title\"]],[[\"@readonly\",\"@table\",\"@displayFlowBar\"],[[30,0,[\"isReadonly\"]],[30,0,[\"table\"]],true]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"rates.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"t\",\"rates-table\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/data/rates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});